import React, { useEffect } from "react"
import { useWindowSize } from "react-use"
import styled from "styled-components"
import { Link, navigate } from "gatsby"

import Layout from "../../../components/layout.js"
import Seo from "../../../components/seo.js"
import { BaseLayout } from "../../../styles/common.js"
import { BackButton } from "../../../styles/buttons.js"
import ArrowIcon from "../../../lib/icons/Arrow.js"
import CurrentUser from "../../../components/CurrentUser/index.js"
import { useStudentStore } from "../../../../store.js"
import { updateCurrentStudentData } from "../../../services/helpers"

const ExercisesLayout = styled(BaseLayout)`
  color: white;
  align-content: start;
  padding-top: 15vh;
`

const ExerciseList = ({ exercises }) => (
  <ul>
    {exercises.map(({ id, input, correct }) => (
      <li key={id}>
        {input} ({correct ? "richtig" : "falsch"})
      </li>
    ))}
  </ul>
)

const ExercisesPage = () => {
  // check if executing instance is the browser or the building process
  const isBrowser = typeof window !== "undefined"

  const { height } = useWindowSize()
  const exercises = useStudentStore((store) => store.exercises)

  const checkStudentData = async () => {
    try {
      const updateSuccessResult = await updateCurrentStudentData()
      console.log("update success:", updateSuccessResult)

      if (!updateSuccessResult.success && isBrowser) {
        navigate("/student", { replace: true })
      }
    } catch (error) {
      console.error(error)
      if (isBrowser) navigate("/student", { replace: true })
    }
  }

  useEffect(() => {
    checkStudentData()
  }, [])

  return (
    <Layout layoutHeight={height}>
      <Seo title="Übungen" />
      <ExercisesLayout>
        <BackButton as={Link} to="/student/profile" alt="Zurück zum Dashboard">
          <ArrowIcon />
        </BackButton>
        <CurrentUser />
        <h1>Deine Übungen</h1>
        <h2>Hier siehst du deine Ergebnisse!</h2>
        <ExerciseList exercises={exercises.covered} />
      </ExercisesLayout>
    </Layout>
  )
}

export default ExercisesPage
